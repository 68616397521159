body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-center {
  text-align: center;
}

.sales-tax-title {
  margin-bottom: -62px;
  margin-right: -700px;
  text-align: center;
}

.col-group {
  align-items: flex-start;
}

.salamat-title-div {
  margin-top: -10px;
}

.customer-div {
  margin-top: -10px;
}

.note-text-css {
  border: 1px solid #000;
}

.text-right {
  text-align: right;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}


.w-100 {
  width: 100%;
}

.justify-content-center {
  justify-content: center;
}

.d-inline-block {
  display: inline-block;
}

.float-right {
  float: right;
}

.desktop-view {
  display: none;
}

.contacts {
  list-style-type: none;
  padding-left: 0px;
}

.overflow {
  overflow: inherit;
}

@media only screen and (min-width: 768px) {
  .float-right-md {
    float: right;
  }
  .desktop-view {
    display: inline-block;
    vertical-align: super;
  }
}

@media only screen and (max-width: 820px) {
  .main-logo img {
    width: 100px !important;
  }
}


.error-message {
  color: red;
  margin-top: 0px;
}

@media print { 
  .header-css, .mantine-Burger-root {
    display: none!important;
  } 
}


.invoice-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.invoice-table th {
  /*background: #f6f6f6;*/
  color: black;
}


.invoice-table td, .invoice-table th {
  border: 1px solid #000;
  text-align: left;
  padding: 15px;
}

.invoice-table tr:nth-child() {
  background-color: #f6f6f6;
}

.transparent-bg {
  background-color: transparent !important;
  border: none;
}

.no-border {
  border: none !important;
}

.print-button {
  margin-top: 10px;
  margin-left: 810px;
}

.text-thanku {
  background: #f6f6f6;
  text-align: center;
}